import BaseAdminOverview from 'spider/semantic-ui/Admin/Overview';
import {TAB_TITLE_PREFIX} from 'helpers';
import {
    Model as BaseModel,
    Store as BaseStore
} from "../store/Base";
import {AdminOverviewButtons} from "./AdminOverview/AdminOverviewButtons";
import {ToolbarItems} from "./AdminOverview/ToolbarItems";
import {AdminOverviewFilters} from "./AdminOverview/Filters";


interface AdminOverviewSetting<
    Model extends BaseModel,
> {
    attr: string | ((model: Model) => JSX.Element | string);
    label?: string;
    sortKey?: string;
    cellProps?: { [name: string]: any };
}


/**
 *  AdminOverview with specifics for the project
 *
 *  Contains some overrides for the typing that is missing from spider as well
 */
export default class AdminOverview<
    Model extends BaseModel,
    Store extends BaseStore
> extends BaseAdminOverview {

    store: Store | undefined;

    // @ts-ignore
    tabTitlePrefix = TAB_TITLE_PREFIX;

    // @ts-ignore
    myFilterKey: string | null;

    // @ts-ignore
    relations: string[]

    filters: AdminOverviewFilters<Model> = [];

    settings: (AdminOverviewSetting<Model> | string)[] = [];

    // @ts-ignore
    buttons: AdminOverviewButtons<Model> = [];

    toolbar: ToolbarItems<Model> = []
}

