import {BinderSocket} from "../../../mobx-spine";
import {Socket} from 'spine-high-templar';


export interface HealthStatus {
    open_connections: number;
    num_rooms: number;
    online_since: string;
    rabbitmq_messages_received: number;
    ws_messages_received: number;
    ws_messages_send: number;
    ws_messages_send_error: number;
    ws_messages_send_queued: number;
}

function startListeningLoop(socket: BinderSocket) {
    return setInterval(async () => {
            await socket.send({
                type: 'status'
            })
        },
        1000
    )
}

function messageIsHealthStatus(message: Record<string, string> | HealthStatus): message is HealthStatus {
    return 'open_connections' in message
}

/**
 * Susbcribe to health Status event. Returns a callback that is needed to unsubscribe
 *
 * @param binderSocket
 */
export function subscribeToHealthStatus(binderSocket: BinderSocket, onMessageReceived: (status: HealthStatus) => void): () => void {
    const socket: Socket = binderSocket as Socket;

    console.log('subscribe')

    const cancelSubscription = socket.on('message', (message) => {
        if (!messageIsHealthStatus(message)) {
            return;
        }
        onMessageReceived(message)
    })


    const listeningLoop = startListeningLoop(binderSocket)

    return () => {
        cancelSubscription()
        clearInterval(listeningLoop)
    }

}
