import {ModuleInterface} from "./module";
import i18next from 'i18n';

/**
 * Keep track op all the enabled modules
 */
export class ModuleState {

    readonly modules: ModuleInterface[] = []

    public addModule(module: ModuleInterface) {
        this.modules.push(module)
    }

    public debug() {
        this.modules.forEach(module => {
            console.log(module.name)
        })
    }

    protected registerLanguagesForModule(module: ModuleInterface) {
        Object.entries(module.translations).map(item => {
            const [language, translation] = item;
            i18next.addResourceBundle(language, module.name, translation, false)
        })
    }
}


export function configureModules(modules: ModuleInterface[]): ModuleState {
    const instance = new ModuleState()
    modules.forEach((module) => instance.addModule(module))
    return instance;
}
