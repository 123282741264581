import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, TopMenu, MenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo.svg';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { ModuleState } from '_iae/module/repository';

const MyLogo = () => (
    <Logo>
        <img src={ImgLogo} height="35" alt="logo"/>
    </Logo>
);

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        modules: PropTypes.instanceOf(ModuleState).isRequired
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderAssets = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
            </NavMenu>
        );
    };


    renderSuperuser = () => {
        const { modules } = this.props;
        return (
            <NavMenu>
                <NavItem
                    title={t('user.overview.title')}
                    to="/assets/user/overview/"
                    activePath="/assets/user/"
                />
                <NavItem
                    title={t('key.overview.title')}
                    to="/manage/key/"
                    activePath="/manage/key/"
                />
                <NavItem
                    title={t('log.overview.title')}
                    to="/log/overview/"
                    activePath="/log/overview/"
                />
                {modules.modules.map(module => {
                    return module.navigationMenu().topMenuItem;
                })}

            </NavMenu>
        )
    }

    renderAdmin = () => (
        <NavMenu>
            <NavItem
                title={t('key.overview.title')}
                to="/manage/key/"
                activePath="/manage/key/"
            />
            <NavItem
                title={t('log.overview.title')}
                to="/log/overview/"
                activePath="/log/overview/"
            />
        </NavMenu>
    );

    renderAccountMenu = () => {
        const { store } = this.props;
        const { version, branch } = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span>
                        <SmallAvatar user={store.currentUser}/>
                        {' '}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                </React.Fragment>
            </NavMenu>
        );
    };

    render() {
        const { currentUser } = this.props.store;


        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <MenuRow>
                        <MyLogo/>
                    </MenuRow>
                    <MenuRow/>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = currentUser.isSuperuser ? this.renderSuperuser() : this.renderAdmin();

        return (
            <React.Fragment>
                <TopMenu>
                    <MenuRow>
                        <MyLogo/>
                        {menu}
                        {this.renderAccountMenu()}
                    </MenuRow>
                    <MenuRow>
                        <NavItem
                            title=""
                            to=""
                            activePath="/foo/"
                        />
                    </MenuRow>
                </TopMenu>
                {logoutModal}
            </React.Fragment>
        );
    }
}
