import {observable} from 'mobx';
import {api, Model, Store} from 'store/Base';
import {User} from "../../../store/User";
import moment from "moment";
import {Casts} from "store/Base";


export class Download extends Model {
    static backendResourceName = 'file_download';

    @observable id: number | null = null;
    @observable uuid = '';
    @observable source = '';
    @observable triggeredBy = this.relation(User);
    @observable triggeredAt = moment();
    @observable readyAt: moment.Moment | null = null;

    @observable file = null;


    casts() {
        return {
            triggeredAt: Casts.datetime,
            readyAt: Casts.nullableDatetime
        }
    }


    save() {
        throw Error('CAnnot save downloads directly!')
        return super.save()
    }
}


export class DownloadStore extends Store {
    Model = Download;
    static backendResourceName = 'file_download';

    async createAsyncDownload() {
        const res = await this.api.post('/file_download/download_async/', {
            params: this.params
        })
        const downloadObject = new Download()
        downloadObject.fromBackend(res)
        return downloadObject;
    }
}
