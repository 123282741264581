import {subscribe} from 'store/Base';
import {Component} from "react";

interface Subscription {
    unsubscribe: () => void;
}

type HighTemplarRoom = {
    target: string;
    triggered_by: string;
} & Record<string, string>

interface SubscribableInterface {
    subscribe: typeof subscribe

}


/**
 * Adds a `this.subscribe` function so that websockets are automatically
 * unsubscribed when the Component unmounts.
 */
export default function <T extends new (...args: any[]) => Component>(SomeComponent: T) {
    return class extends SomeComponent implements SubscribableInterface {

        // constructor(...args: any[]) {
        //     super(...args);
        // }

        /**
         * Contains all websocket subscriptions.
         */
        subscriptions: Subscription[] = [];

        subscribe = (room, callback) => {
            const supscription = subscribe(room, callback);

            this.subscriptions.push(supscription);

            return supscription;
        }

        unsubscribe = () => {
            this.subscriptions.forEach(s => s.unsubscribe());
            this.subscriptions = [];
        }

        componentWillUnmount() {
            super.componentWillUnmount?.();

            this.unsubscribe();
        }
    }
}
