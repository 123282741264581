import {DownloadButton} from "../../../spider/semantic-ui/Button";
import {observer} from "mobx-react";
import {Download} from "../store/Download";
import React, {Component} from "react";
import {ButtonProps} from "semantic-ui-react/dist/commonjs/elements/Button/Button";

interface FileDownloadButtonProps extends ButtonProps{
    download: Download;
}

@observer
export class FileDownloadButton extends Component<FileDownloadButtonProps> {
    static defaultProps = {
        ...DownloadButton.defaultProps,
        'data-test-download-button': true,
    }

    render() {
        const {download, ...rest} = this.props;

        return (
            <DownloadButton
                {...rest}
                download
                primary
                as="a"
                href={download.file}
            />
        )
    }
}
