import i18next, {Resource as AppTranslations} from 'i18next';
import nl from 'i18n/translation/nl';
import {ModuleState} from "./_iae/module/repository";
import {Translation} from "./_iae/module/module";
import {merge} from 'lodash';

type ModuleTranslations = {
    [language: string]: Translation[]
}

/**
 * Sort all the translations from the modules by language.
 *
 * Returns a dict, with the keys being the language and the values being a list of all the module translations
 * @param modules
 */
function getModuleTranslations(modules: ModuleState): ModuleTranslations {
    const moduleTranslations: ModuleTranslations = {}

    modules.modules.forEach(module => {
        Object.entries(module.translations).forEach(
            ([language, translation]) => {
                if (!(language in moduleTranslations)) {
                    moduleTranslations[language] = []
                }
                moduleTranslations[language].push(translation)
            }
        )
    })

    return moduleTranslations;
}

/**
 * Merge the translations from the modules to the translations to the app
 *
 * Done in a way that the app translations always overwrite the module translations
 *
 * @param appTranslation
 * @param moduleTranslations
 */
function mergeTranslations(appTranslation: AppTranslations, moduleTranslations: ModuleTranslations): AppTranslations {

    const languages = Object.keys(appTranslation);

    languages.forEach(language => {
        merge(
            appTranslation[language]['translation'],
            ...(moduleTranslations[language] || []),
        )
    })
    return  appTranslation;
}

export function configureTranslations(modules: ModuleState): void {

    const appTranslations: AppTranslations = {
        nl:
            {
                description: 'Nederlands',
                translation: nl
            },
    }

    const moduleTranslations = getModuleTranslations(modules)

    console.log('sehresh', moduleTranslations, appTranslations);

    const mergedTranslation = mergeTranslations(appTranslations, moduleTranslations);



    i18next.init({
        // Some tests rely that default lang is 'en'. One of those tests is
        // resetPassword.T31471.cy.js. There the page will rerender after
        // bootstrap is fetched if the lang is different than the default. This
        // causes the test to be flaky, depending if the bootstrap happens before
        // or after rendering the page.
        lng: 'nl',
        resources: mergedTranslation,
        interpolation: {
            escapeValue: false, // not needed for react as it does escape per default to prevent xss!
        },
    });
}


export default i18next;
export const t = i18next.t.bind(i18next);
