import React, {Component,} from "react";
import {observable} from "mobx";
import {DownloadStore} from "../store/Download";
import {StyledPageLoader} from "../../../component/Loader";
import {CenteredContent} from "../component/CenteredContent";
import {Header} from "semantic-ui-react";
import {t} from "i18next";
import {observer} from "mobx-react";
import {FileDownloadButton} from "../component/FileDownloadButton";

interface DownloadScreenInterface {
    match: {
        params: {
            uuid: string
        }
    }
}


@observer
export class DownloadScreen extends Component<DownloadScreenInterface> {

    @observable downloadStore = new DownloadStore()

    constructor(props) {
        super(props);

        this.downloadStore.params['.uuid'] = props.match.params.uuid

        console.log(props.match.params.uuid)
    }

    componentDidMount() {
        this.downloadStore.fetch()
    }

    render() {

        if (this.downloadStore.isLoading) {
            return <StyledPageLoader/>
        }

        if (this.downloadStore.length === 0) {
            return (
                <CenteredContent>
                    <Header>{t('download.fileNotFound')}</Header>
                </CenteredContent>
            )
        }

        return (
            <CenteredContent>
                    <FileDownloadButton download={this.downloadStore.at(0)} size="massive" />

            </CenteredContent>
        )

    }

}
