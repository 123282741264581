import React, {Component} from 'react';
import {NavItem} from 're-cy-cle';
import {t} from "i18n";
import {DownloadOverviewScreen} from "./screen/DownloadOverview";
import {DownloadScreen } from "./screen/Download";
import {ModuleInterface} from "../module/module";
import nlTranslations from "_iae/async-file-handling/translations/nl";
import enTranslations from "_iae/async-file-handling/translations/en";

export const Module: ModuleInterface = {
    name: 'async-file-handling',
    navigationMenu: () => ({
        basePath: 'file-handling',
        topMenuItem: (
            <NavItem
                title={t('nav.main.fileHandling' )}
                to="/file-handling/download/overview"
                activePath="/file-handling/"
            />
        ),
        subMenuItems: [
            <NavItem
                title={t('nav.fileHandling.download')}
                to="/file-handling/download/overview"
                activePath="/file-handling/download/"
            />
        ]
    }),
    routes: [
        {
            path: '/file-handling/download/download/:uuid',
            screen: DownloadScreen
        },
        {
            path: "/file-handling/download/overview",
            screen: DownloadOverviewScreen
        },
    ],
    translations: {
        'nl': nlTranslations,
        'en': enTranslations
    }
}
